import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    downloadProgress: 0, // 记录下载进度
    isMobile: false,
    downloadFile: {
      path: "https://cdn-digroup.qiniu.mrtangyun.com/2024%E5%8C%97%E4%BA%AC%E8%87%AA%E7%84%B6%E5%9F%8E%E5%B8%82%E5%8F%A4%E6%A0%91%E8%A7%82%E8%B5%8F%E6%8C%87%E5%8D%97.pdf",
      // path:  "https://bucket-tangyun-1.oss-cn-beijing.aliyuncs.com/audi-ancient-tree/2024%E5%8C%97%E4%BA%AC%E8%87%AA%E7%84%B6%E5%9F%8E%E5%B8%82%E5%8F%A4%E6%A0%91%E8%A7%82%E8%B5%8F%E6%8C%87%E5%8D%97.pdf",
      fileName: "2024北京自然城市古树观赏指南.pdf"
    },
    trees: [
      {
        title: "北京侧柏之王",
        subTitle: "密云区新城子镇九搂十八杈",
        imgAuthor: "九搂十八杈（杨树田 摄）",
        img: require("@/images/trees/2.1-九搂十八杈（杨树田-摄）.jpg"),
        detail: [
          {
            items: [
              "侧柏，柏科侧柏属常绿乔木，拉丁名：<i>Platycladus orientalis</i> (L.) Franco",
              "树龄约3500年，是北京地区最老的古树。胸围780厘米，树高18米，冠幅15米。",
              "因其粗干需好几个人伸臂合围才能抱拢，巨冠由18个大杈组成，所以得名“九搂十八杈”。2023年入选全国“双百”古树。",
              "省级公路松曹路原本紧邻九搂十八杈，修建的挡墙导致古树根系伸展不开，影响了古树生长。从2021年至2022年，相关部门采取松曹路东移16米、修建古柏公园等一系列保护措施，为九搂十八杈创造了良好的生长环境。",
              "侧柏为北京市两种市树之一，也是北京地区数量最多的古树。侧柏生长缓慢，生长期长。全国树龄5000年以上的古树一共有5株，全部为侧柏。"
            ]
          }
        ]
      },
      {
        title: "北京银杏之王",
        subTitle: "门头沟区潭柘寺帝王树",
        imgAuthor: "潭拓寺帝树王（杨树田-摄)",
        img: require("@/images/trees/2.2潭拓寺帝树王（杨树田-摄).jpg"),
        detail: [
          {
            items: [
              "银杏，银杏科银杏属落叶乔木，拉丁名：<i>Ginkgo biloba</i> L.",
              "树龄超1300年。胸围约1100厘米，树高30米，冠幅18.5米。",
              "相传在清代，每当一个帝王登基，这棵古老的银杏就会生出一个新的侧枝，因此乾隆皇帝御封此树为“帝王树”，这是中国历史上皇帝对树木的最高封号。2023年入选全国“双百”古树。",
              "银杏属于裸子植物门银杏纲银杏目银杏科银杏属，是银杏纲唯一的一种植物，也是古代银杏类植物在地球上存活的唯一品种，最早出现于3.45亿年前的石炭纪，因此被看作植物界的“活化石”、“大熊猫”。",
              "200多万年前，第四纪冰川运动后，绝大多数银杏类植物在世界其他地区绝种，只有在中国的生存下来。所以，现如今世界各地的其他银杏都是直接或间接从中国传入的。",
              "银杏为国家重点保护野生植物（一级），同时被列入《世界自然保护联盟濒危物种红色名录》（IUCN 1998年 ver 2.3）——濒危（EN）。",
              "为何在园林绿化中常见的银杏，会是濒危植物呢？目前我们能看到的银杏，绝大多数都是人工栽培的。判断植物珍稀程度的标准，数量并非是决定性因素，其基因的多样性和野生种群的分布情况才是最重要的。从目前的研究来看，银杏野生种群的分布和数量都极为稀少，甚至中国还有没有野生银杏的存在，学术界还存在着很大争议。因此，从生物学层面上，银杏被划为国家重点保护野生植物、列入濒危（EN），也就成为了一种必然。"
            ]
          }
        ]
      },
      {
        title: "北京白皮松之王",
        subTitle: "门头沟区戒台寺九龙松",
        imgAuthor: "戒台寺九龙松（杨树田-摄）",
        img: require("@/images/trees/2.3-戒台寺九龙松（杨树田-摄）.jpg"),
        detail: [
          {
            items: [
              "白皮松，松科松属常绿乔木，拉丁名：<i>Pinus bungeana</i> Zucc.ex Endl.",
              "树龄约1300年，是北京最古老的白皮松。胸围650厘米、树高18米、冠幅23米。",
              "因主干分九枝而得名，与卧龙松、抱塔松、自在松、活动松并称为戒台寺五大名松。",
              "白皮松是中国特有树种，因树皮颜色而得名，其实中青年白皮松树皮为灰绿色，随着生长树皮会呈薄片状脱落，剥落处绿白色，整体看去像穿着迷彩衣，长到壮年至古树，树皮更多的是白色，显得更加苍劲有力。"
            ]
          }
        ]
      },
      {
        title: "北京国槐之王",
        subTitle: "北海公园唐槐",
        imgAuthor: "北海公园唐槐（杨树田-摄）",
        img: require("@/images/trees/2.4-北海公园唐槐（杨树田-摄）.jpg"),
        detail: [
          {
            items: [
              "国槐，豆科槐属落叶乔木，拉丁名：<i>Styphnolobium japonicum</i> (L.) Schott",
              "树龄1200年。胸围约600厘米、树高13米、冠幅9米。",
              "因于唐代种植而得名。清乾隆帝对这棵古槐喜爱有加，在树侧修筑屋宇，点缀太湖石，并以古槐为由取名“古柯庭”，还写有《御制古槐诗》，诗中云：“庭宇老槐下，因之名古柯。若寻嘉树传，当赋角弓歌。”",
              "国槐又名槐、家槐，原产中国北部，远在秦汉时期，自长安至诸州的通道便已有夹路植槐的记述，为中国特产树种。国槐是北京市两种市树之一。近代被称为国槐更多地是为了和洋槐进行区分，要分辨国槐和洋槐可以看两点：洋槐树枝上有刺，国槐则没有；花期不同，洋槐每年四五月份开花，花朵颜色为白色，有浓郁的香味，其总状花序下垂，国槐每年七八月份开花，花朵颜色为黄绿色，香味不大，其总状花序是上升的。"
            ]
          }
        ]
      },
      {
        title: "北京油松之王",
        subTitle: "海淀区车耳营迎客松",
        imgAuthor: "车耳营迎客松（杨树田-摄）",
        img: require("@/images/trees/2.5-车耳营迎客松（杨树田-摄）.jpg"),
        detail: [
          {
            items: [
              "油松，松科松属常绿乔木，拉丁名：<i>Pinus tabuliformis</i> Carrière",
              "树龄1000余年。胸围350厘米，树高7米，冠幅16米。",
              "生长在海淀区凤凰岭自然风景区车耳营村关帝庙前，为辽代所植。它南侧的一个主枝向大道上长长地伸延，好像在迎接过往的来客，故名“迎客松”。",
              "油松是中国特有树种。木材富含松脂，树干可割取松脂，提取松节油。"
            ]
          }
        ]
      },
      {
        title: "北京酸枣之王",
        subTitle: "东城区花市酸枣王",
        imgAuthor: "花市酸枣王（杨树田-摄）",
        img: require("@/images/trees/2.6-花市酸枣王（杨树田-摄）.jpg"),
        detail: [
          {
            items: [
              "酸枣，鼠李科枣属木本植物，拉丁名：<i>Ziziphus jujuba var. spinosa</i> (Bunge) Hu ex H.F.Chow.",
              "树龄800年。胸围约400厘米，树高15米，冠幅11米。",
              "位于东城区花市枣苑小区，所在小区因它得名。这棵酸枣树为金代所种植，历经近千年的风霜雨雪，也曾遭遇雷击和明清两代的几次冻灾，至今依然枝繁叶茂、春花秋实，实属难能可贵，被人们视为吉祥树。",
              "酸枣原产于中国。"
            ]
          }
        ]
      },
      {
        title: "北京桧柏之王",
        subTitle: "天坛九龙柏",
        img: require("@/images/trees/5751727260791.jpg")
      },
      {
        title: "北京榆树之王",
        subTitle: "延庆千家店镇千家店村榆树王",
        img: require("@/images/trees/111.jpg")
      },
      {
        title: "北京海棠之王",
        subTitle: "宋庆龄故居中院东侧古西府海棠",
        img: require("@/images/trees/221.jpg")
      },
      {
        title: "北京玉兰之王",
        subTitle: "海淀区颐和园邀月门东侧古玉兰",
        img: require("@/images/trees/331.jpg")
      }
    ],
    specialAddress: [
      {
        articleId: "gugong"
      },
      {
        articleId: "taimiao"
      },
      {
        articleId: "tiantan"
      }
    ],
    articles: [
      {
        id: "what_is_an_ancient_tree",
        title: "什么是古树",
        topImg: require("@/images/shenmeshigushu/什么是古树.jpg"),
        children: [
          {
            type: "header",
            content: "古树名木的定义"
          },
          {
            type: "html",
            content: `古树是指树龄在100年以上的树木；
名木是指珍贵、稀有的树木，具有历史、文化、科研价值的树木和重要纪念意义的树木。`
          },

          {
            type: "header",
            content: "古树的分类、分级"
          },
          {
            type: "html",
            content: `依据国家林业局发布的《古树名木鉴定规范LYT 2737-2016》将古树分为一、二、三级。
<span style="color:#45c26b">一级古树：</span>树龄在500年以上的树木；
<span style="color:#45c26b">二级古树：</span>树龄在300年～499年的树木；
<span style="color:#45c26b">三级古树：</span>树龄在100年～299年的树木。`
          },
          {
            type: "html",
            content: `依据北京市发布的《古树名木评价标准DB11/T478—2007》将古树分为一、二级。
<span style="color:#45c26b">一级古树：</span>树龄在300年（含300年）以上的树木；
<span style="color:#45c26b">二级古树：</span>树龄在100年（含100年）以上、300年以下的树木。`
          },
          {
            type: "img",
            content: require("@/images/shenmeshigushu/北京市古树树牌.png")
          },
          {
            type: "author",
            content: "北京市古树树牌（袁方 摄）"
          },
          {
            type: "html",
            content: `在古树的树干上，我们常常会看到悬挂着不同颜色的树牌，这些树牌上记载着古树的编号、科属、学名、年代等信息。2017年北京市园林绿化局统一规制为每一株古树定制了树牌，红色树牌代表一级古树，绿色树牌代表二级古树。每株古树都制定了具有唯一性的编号与二维码，方便人们认识古树、了解古树、更好地保护古树。`
          },
          {
            type: "header",
            content: "古树的形态"
          },
          {
            type: "html",
            content: `古树形态独特而壮观，可谓是“千姿百态”。有的树干粗壮，需要几人才能抱拢；有的树干扭曲，仿佛是飞腾的巨龙；有的树冠庞大，如同巨伞；还有的直立挺拔，树皮雪白，如同威武的将军。因此，人们根据树木的姿态，为其赋予独特的称号。比如：需要9个人才能抱拢的“九搂十八杈”，树干粗壮且拥有9个大枝的“九龙松”，树冠庞大、如巨伞遮阴的“遮荫侯”等。古树的形态既是大自然的杰作，也是生命力的象征，向人们展现着生命的不易与坚韧。`
          },
          {
            type: "img",
            content: require("@/images/shenmeshigushu/戒台寺-九龙松（袁方-摄）.jpg")
          },
          {
            type: "author",
            content: "戒台寺-九龙松（袁方 摄）"
          }
        ]
      },

      {
        id: "Methods for identifying ancient trees",
        title: "古树的鉴定方法",
        topImg: require("@/images/page3-content.jpg"),
        children: [
          {
            type: "header",
            content: "树种鉴定"
          },
          {
            type: "html",
            content: `我们国家的古树种类繁多，其中数量较多的古树包括樟树、柏树、银杏、松树、国槐等。北京最常见的古树树种包括侧柏、圆柏、油松、白皮松、银杏和国槐等。
<span class="green-underline">古樟树</span>（Cinnamomum camphora (L.) J. Presl）一般在外形上树冠庞大，树冠东西及南北方向冠幅可达30米，树高可达30米，古樟树的叶片呈卵圆形，古樟树的果实为圆球形，接近于黄豆大小，成熟后呈紫黑色。`
          },
          {
            type: "html",
            content: `<span class="green-underline">古柏树</span>在外形上树干通直，树皮为浅灰褐色或深褐色，树皮一般会纵向开裂成条状，常见的古柏树有古侧柏（Platycladus orientalis (L.) Franco）及古圆柏（Sabina chinensis (L.) Ant.），也叫桧柏，其中叶片全部都是鳞形叶的为侧柏古
树，叶片既有刺形又有鳞形的一般为古圆柏树，通过这一特征的区别就可以在观赏过程种区分这两种古柏树。`
          },
          {
            type: "html",
            content: `<span class="green-underline">古银杏树</span>（Ginkgo biloba L.）的典型特征是叶片为扇形，在叶片的上边缘有波状的缺刻，银杏的果实接近圆球形，果实表面有白粉，果实成熟之后会变成黄色。`
          },
          {
            type: "html",
            content: `<span class="green-underline">古油松</span>（Pinus tabuliformis Carr.）及古白皮松（Pinus bungeana Zucc. ex Endl.）的典型特征为叶片为针形，油松两针一束，白皮松为三针一束，白皮松古树树皮为灰色或灰白色，树皮块片脱落后会露出粉白色内皮，故称之为白皮松。`
          }
        ]
      },
      {
        id: "gugong",
        title: "故宫",
        topImg: require("@/images/gugong/02.故宫蟠龙槐（杨树田-摄）.jpg"),
        children: [
          {
            type: "header",
            content: "概况"
          },
          {
            type: "html",
            content: `故宫是中国明清两代的皇家宫殿，旧称紫禁城，位于北京中轴线的中心。故宫于明永乐四年（1406年）开始建设，到永乐十八年（1420年）建成，成为明清两朝二十四位皇帝的皇宫，是世界上现存规模最大、保存最为完整的木质结构古建筑群之一。
据说是出于防火、防雷、防盗并突出雄伟气势等多种原因考虑，除去御花园、乾隆花园、建福宫花园外，其他建筑物密集之地，树木不算多。紫禁城区域内有古树名木448株，加上大高玄殿区域内的36株，慈慧胡同1株，共计485株。其中，一级古树名木115株，二级古树名木370株。`
          },
          {
            type: "image",
            content: require("@/images/gugong/北京古树游览推荐-故宫.jpg")
          },
          {
            type: "author",
            content: `故宫古树观赏地图`
          },
          {
            type: "header",
            content: "故宫蟠龙槐"
          },
          {
            type: "html",
            content: `<span class="green-underline">龙爪槐，一级古树</span>
树龄约500年。位于御花园琼苑东门内。
为北京龙爪槐之最，树冠东西长10.35米，南北长9.3米。
龙爪槐是国槐的芽变品种，需人工嫁接繁殖。`
          },
          {
            type: "image",
            content: require("@/images/gugong/02.故宫蟠龙槐（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          },
          {
            type: "header",
            content: "故宫连理柏"
          },
          {
            type: "html",
            content: `<span class="green-underline">桧柏，一级古树</span>
树龄200多年。位于御花园天一门前。
连理柏由两棵古柏形成，故宫共有两株连理柏，钦安殿前的连理柏为明代栽植，天一门前由乾隆皇帝授意补植，两株连理柏其主干都正巧跨过北京的中轴线。
御花园里能看到奇特的连理柏和人字柏，它们是古代高超园艺技术的见证。连理柏是利用圆柏小枝成弧状弯曲的特性，将两幼树小枝树皮拨开并靠接在一起，靠接处形成层逐渐融合，产生新的树皮，两棵树就此连接在一起。照技术难度来说，明代的连理柏培育技术更为复杂，两树侧枝透过木质部相互穿插，衔接更紧密；清代则仅由韧皮部相连，靠接并不紧密。人字柏是在桧柏幼龄时，将树自树干约1.5米处向下劈开至根部，包裹伤口后以人字形栽植于树池内，开裂口形成层愈合后产生新的树皮。`
          },
          {
            type: "image",
            content: require("@/images/gugong/03.故宫连理柏（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          },
          {
            type: "header",
            content: "古楸树"
          },
          {
            type: "html",
            content: `<span class="green-underline">楸树，2株，一级古树</span>
树龄约400年。位于御花园坤宁门外。
楸树是除了松柏外，紫禁城花园中种植最多的乔木。这两株楸树守在坤宁门两侧，配合通向天一门的香炉及神兽，直接烘托了园子庄重的宗教氛围。`
          },
          {
            type: "image",
            content: require("@/images/gugong/04.古楸树（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          }
        ]
      },
      {
        id: "taimiao",
        title: "劳动人民文化宫（太庙）",
        topImg: require("@/images/taimiao/太庙.jpg"),
        children: [
          {
            type: "header",
            content: "概况"
          },
          {
            type: "html",
            content: `劳动人民文化宫位于北京天安门东侧，曾是明、清两代皇室家庙，旧称太庙。始建于明永乐十八年（公元1420年），依据古代王都“左祖右社”的规制，与故宫、社稷坛同时建造，是紫禁城重要的组成部分。总面积13.9万平方米，有三道红墙环绕，是中国现存最完整的、规模最宏大的皇家祭祖建筑群，是古代最重要的宗庙建筑，堪称“天下第一庙”。
太庙环庙植柏，遗存古树714株，绝大部分都是侧柏。这些古柏多为明代太庙初建时所植，少数为清代补种,其中包括一级古树497株，二级古树217株。这些古柏环绕太庙中心建筑群，与黄瓦红墙交相辉映，形成肃穆、清幽的环境。`
          },
          {
            type: "image",
            content: require("@/images/taimiao/北京古树游览推荐-太庙.jpg")
          },
          {
            type: "author",
            content: `太庙古树观赏地图`
          },
          {
            type: "header",
            content: "神柏"
          },
          {
            type: "html",
            content: `<span class="green-underline">侧柏，一级古树</span>
树龄约600年。位于劳动人民文化宫南门往北约百米。
相传为太庙始建时，明成祖朱棣手植的第一棵树。当初太庙遍植新柏，连种三次均未成活，一工匠献计换上沃土，并请朱棣首栽此树，果然成活，遂称为“神柏”。后代皇帝、亲贵祭祖至此须下轿、下马，以示尊重。`
          },
          {
            type: "image",
            content: require("@/images/taimiao/02.神柏（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          },
          {
            type: "header",
            content: "明成祖手植柏"
          },
          {
            type: "html",
            content: `<span class="green-underline">侧柏，一级古树</span>
树龄约600年。位于劳动人民文化宫后殿北。
柏高13.5米，树干径围5.5米。枝叶繁茂，茁壮挺拔，独领群柏之首。相传为明成祖朱棣亲手所植，以告慰先祖社稷安定，并抒发治国安邦之宏愿。`
          },
          {
            type: "image",
            content: require("@/images/taimiao/03.明成祖手植柏（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          },
          {
            type: "header",
            content: "鹿型柏"
          },
          {
            type: "html",
            content: `<span class="green-underline">侧柏，一级古树</span>
树龄近300年。位于劳动人民文化宫西门内偏北。
此柏植于清代。从东北方向观看，其枝干树冠形如一梅花鹿，两个树洞，恰似嘴和眼，顾盼机警，奔驰跳跃，静中有动。太庙幽禁时，树旁原有灰鹤栖息，被视为松柏常青、鹿鹤同春的吉象。`
          },
          {
            type: "image",
            content: require("@/images/taimiao/04.鹿型柏（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          }
        ]
      },
      {
        id: "tiantan",
        title: "天坛公园",
        topImg: require("@/images/tiantan/天坛.jpg"),
        children: [
          {
            type: "header",
            content: "概况"
          },
          {
            type: "html",
            content: `天坛公园原名“天地坛”，始建于明永乐十八年（1420年），明嘉靖九年（1530年）改名为“天坛”，是明清两代皇帝“祭天”“祈谷”的场所，总面积273公顷，是中国现存最大的古代祭祀性建筑群。
坛庙在中国古代被誉为国家的“万世不移”之基，故中国古代对坛庙植树非常重视。明永乐年间初建北京天地坛时即“树以松柏”取“尊而识之”的寓意，以后历朝陆续补植，至清代中叶形成具有一定规模的天坛古树群落。现在天坛公园有古树3562株，其中一级古树1147株，包含668株侧柏、478株桧柏和1株国槐，二级古树2415株，包括1667株侧柏、724株桧柏、21株国槐、2株银杏和1株油松。`
          },
          {
            type: "image",
            content: require("@/images/tiantan/北京古树游览推荐-天坛.jpg")
          },
          {
            type: "author",
            content: `天坛公园古树观赏地图`
          },
          {
            type: "header",
            content: "九龙柏"
          },
          {
            type: "html",
            content: `<span class="green-underline">桧柏，一级古树</span>
树龄500余年。位于皇穹宇西北侧。
传说乾隆一次到天坛祭天，在皇穹宇休息时听到怪声，寻找后发现围墙下有九条蛇，瞬间钻入了泥土中。乾隆命人出皇穹宇抓蛇，四寻不见，忽见一棵树树干表面布满沟纹，就像九龙腾飞，所以起名“九龙柏”。`
          },
          {
            type: "image",
            content: require("@/images/tiantan/02.九龙柏（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          },
          {
            type: "header",
            content: "问天柏"
          },
          {
            type: "html",
            content: `<span class="green-underline">桧柏，一级古树</span>
树龄约500年。位于皇穹宇西南。
该树树冠上有一根大枯枝，其上部向下倾斜，仿佛是一个人在仰天长叹，神似屈原问天。`
          },
          {
            type: "image",
            content: require("@/images/tiantan/03.问天柏（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          },
          {
            type: "header",
            content: "迎客柏"
          },
          {
            type: "html",
            content: `<span class="green-underline">桧柏，一级古树</span>
树龄约600年。坐落于皇穹宇东北侧的成贞门旁。
它主干的东侧已没有大枝，西侧的几个大枝都是沿着水平方向生长，尤其是最下面的一个大枝正巧横在月亮门的上方，游人出入月亮门必须要在这根大树枝下穿过，这是名副其实的“迎客”。这棵迎客柏已和月亮门融为一体，成为园内一景。`
          },
          {
            type: "image",
            content: require("@/images/tiantan/04.迎客柏（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `杨树田 摄`
          }
        ]
      },
      {
        id: "ancient-tree-viewing-methods",
        title: "古树的观赏方法",
        topImg: require("@/images/viewing-methods/头图.jpg"),
        children: [
          {
            type: "header",
            content: `季节观赏法`
          },
          {
            type: "html",
            content: `通过观赏不同季节下古树的形态、色彩和氛围，可以更深刻地体会到古树随季节变化的生命力。春季的生机、夏季的繁茂、秋季的绚烂、冬季的静谧，在不同的季节观赏不同的古树风采，让人对古树和自然有了更深的敬畏和感悟。`
          },
          {
            type: "image",
            content: require("@/images/viewing-methods/1.-季节观赏法-春季-西府海棠（袁方-摄）.jpg")
          },
          {
            type: "author",
            content: `季节观赏法-春季-西府海棠（袁方 摄）`
          },
          {
            type: "image",
            content: require("@/images/viewing-methods/2.-季节观赏法-夏季-古流苏（马晓燕-摄）.jpg")
          },
          {
            type: "author",
            content: `季节观赏法-夏季-古流苏（马晓燕 摄）`
          },
          {
            type: "image",
            content: require("@/images/viewing-methods/3.-季节观赏法-秋季-四桥子村大神树（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: ` 季节观赏法-秋季-四桥子村大神树（杨树田 摄）`
          },
          {
            type: "image",
            content: require("@/images/viewing-methods/4.-季节观赏法-冬季-戒台寺卧龙松（杨树田-摄）.jpg")
          },
          {
            type: "author",
            content: `季节观赏法-冬季-戒台寺卧龙松（杨树田 摄）`
          },
          {
            type: "header",
            content: "细节观赏法"
          },
          {
            type: "html",
            content: `通过观赏古树的枝叶、树干、花果、树皮纹理等细节，感受古树的魅力与内涵。
古树的枝叶往往精致而优美，每一片叶子都像是精心雕琢的艺术品，叶片的颜色从嫩绿到深绿，再到秋日里的金黄、火红，尽情展现着枝叶的优美。
古树的树干，往往呈现出一种独特的苍劲与力量感。它们或笔直挺拔、或虬曲盘旋，如龙蛇飞舞。树干上的裂纹与疤痕，是岁月留下的痕迹，体现着古树坚韧不拔的精神。触摸这些树干，仿佛能感受到历史的厚重与生命的坚韧。
当古树开花结果时，花果往往充满整个树冠，绚丽饱满，令人震撼。古树的花型各异，有的如繁星点点，有的似云霞缭绕，花香沁人心脾。而当果实挂满枝头，从青涩渐变为成熟，形状、颜色各异，不仅展示了生命的丰盈与多彩，更蕴含着深厚的文化寓意和生态价值。
古树的纹理展现着最质朴而深邃的美，如同山川的脉络与历史的印记相融合，每一道纹理都蕴含着大自然的奥秘与时间的智慧。这些纹理，就像是古树的语言，虽然无声，却能触动人心，让人在静谧中感受古树的精美与震撼。`
          },
          {
            type: "image",
            content: require("@/images/viewing-methods/1.-细节观赏法-天坛九龙柏（柏慧-摄）.jpg")
          },
          {
            type: "author",
            content: `细节观赏法-天坛九龙柏 （柏慧 摄）`
          },

          {
            type: "header",
            content: "信息观赏法"
          },
          {
            type: "html",
            content: `信息观赏法则是从科学或历史的角度去解读古树中蕴含的信息，我们可以根据古树上的树牌了解古树的编号、科属、学名、年代、位置、树高、胸径、历史文化等信息。信息观赏法让我们在欣赏古树之美的同时，也能够深入了解其背后的科学知识、历史渊源和文化价值。`
          },

          {
            type: "header",
            content: "光影观赏法"
          },
          {
            type: "html",
            content: `光影观赏法，是一种将自然光影变化与古树之美巧妙结合的观赏方式。它利用不同时间、不同季节下光线与树影的相互作用，展现出古树独特而迷人的风貌，让观赏者在光影交错间体验到古树生命力的律动与自然的韵律。`
          },
          {
            type: "image",
            content: require("@/images/viewing-methods/1.-劳动人民文化宫九龙柏（袁方-摄）.jpg")
          },
          {
            type: "author",
            content: `劳动人民文化宫九龙柏 （袁方 摄）`
          },

          {
            type: "header",
            content: "情感寄托观赏法"
          },
          {
            type: "html",
            content: `情感寄托观赏法是古树观赏的独特体验。在观赏中，古树不再仅仅是自然界中的一个静态存在，而是被赋予了生命力和情感色彩的载体。它让人们更加深入地体会到古树的韵味和魅力，增强了我们的参与感和归属感，并帮助人们缓解压力、放松心情，提升人们的生活质量和幸福感。`
          },
          {
            type: "image",
            content: require("@/images/viewing-methods/1.-东岳庙状元槐（赵晴-摄）.jpg")
          },
          {
            type: "author",
            content: `东岳庙状元槐 （赵晴 摄）`
          },
          {
            type: "image",
            content: require("@/images/viewing-methods/2.-潭柘寺柏柿如意（马晓燕-摄）.jpg")
          },
          {
            type: "author",
            content: `潭柘寺柏柿如意（马晓燕 摄）`
          }
        ]
      }
    ]
  },
  mutations: {
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setDownloadProgress(state, progress) {
      state.downloadProgress = progress;
    }
  },
  actions: {},
  modules: {}
});
