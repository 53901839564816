import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/common.scss";
import "./util/flexlib";

import NavigatorBar from "./components/NavigatorBar/index";
import downloadDirective from "./directives/downloadDirective";
import {
  Button,
  Icon,
  PullRefresh,
  Sticky,
  List,
  Dialog,
  Picker,
  Popup,
  Toast,
  Skeleton,
  ImagePreview,
  NavBar,
  Swipe,
  SwipeItem
} from "vant";

// import VConsole from "vconsole/dist/vconsole.min.js";

// new VConsole();
Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Icon);
Vue.use(PullRefresh);
Vue.use(Sticky);
Vue.use(List);
Vue.use(Dialog);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Skeleton);
Vue.use(ImagePreview);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.use(NavigatorBar);
Vue.directive("download", downloadDirective);
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

loadAnalyticsScript();

function loadAnalyticsScript() {
  let token = "";

  // 根据不同的域名设置不同的监控代码
  switch (window.location.hostname) {
    case "tree.mrtangyun.uk":
      token = "8eb64fbd98cb4383bab5ab419103f191";
      break;
    case "tree.mrtangyun.com":
      token = "315ca0fa2a1a4a9b87c97808b8169ccd";
      break;
    case "tree-digroup.mrtangyun.com":
      token = "30a9a45dcfbb438ba43d287809c380ce";
      break;
    default:
      console.warn("No analytics token for this domain");
      return;
  }

  // 创建 script 标签并设置属性
  const script = document.createElement("script");
  script.defer = true;
  script.src = "https://static.cloudflareinsights.com/beacon.min.js";
  script.setAttribute("data-cf-beacon", JSON.stringify({ token }));

  // 将 script 标签插入到 body 中
  document.body.appendChild(script);
}
