import { Dialog } from "vant";
const downloadQueue = new Map();
export default {
  bind(el, binding, vnode) {
    const { commit, state } = vnode.context.$store; // 获取 Vuex 的 commit 方法

    el.addEventListener("click", async () => {
      const url = binding.value.url;
      const fileName = binding.value.fileName || "download";

      // 如果这个URL已经在下载队列中，则不执行新的下载
      if (downloadQueue.has(url)) {
        console.log("Download already in progress for:", url);
        await Dialog.alert({
          message: `正在下载，当前进度 ${state.downloadProgress.toFixed(2)} %！`
        });
        return;
      }
      await Dialog.confirm({
        title: "提示",
        message: "是否下载《2024北京自然城市古树观赏指南》？"
      });

      // 将URL添加到下载队列
      downloadQueue.set(url, true);

      window._hmt.push([
        "_trackEvent",
        "文件下载",
        "开始",
        "2024北京自然城市古树观赏指南"
      ]);
      fetch(url)
        .then((response) => {
          const contentLength = response.headers.get("Content-Length");
          const reader = response.body.getReader();
          const total = parseInt(contentLength, 10);
          let loaded = 0;

          let chunks = []; // 用于存储读取的字节数据
          const link = document.createElement("a"); // 在这里创建 link

          const read = () => {
            return reader.read().then(({ done, value }) => {
              if (done) {
                window._hmt.push([
                  "_trackEvent",
                  "文件下载",
                  "下载完成",
                  "2024北京自然城市古树观赏指南"
                ]);
                // 下载完成
                const blob = new Blob(chunks); // 创建 Blob
                const blobUrl = URL.createObjectURL(blob); // 创建 Blob URL
                link.href = blobUrl; // 设置链接 href 为 Blob 的 URL
                link.download = fileName; // 设置文件名
                link.click(); // 触发下载
                URL.revokeObjectURL(blobUrl); // 撤销 Blob 的 URL
                return;
              }

              chunks.push(value); // 保存读取的块
              loaded += value.length; // 更新已加载的字节数
              commit("setDownloadProgress", (loaded / total) * 100); // 更新下载进度

              // 继续读取
              return read(); // 递归调用以继续读取流
            });
          };

          return read();
        })
        .catch(console.error)
        .finally(() => {
          // 下载完成后从队列中移除
          downloadQueue.delete(url);
          commit("setDownloadProgress", 0); // 重置下载进度
        });
    });
  },

  // 在指令解绑时清理事件监听器
  unbind(el) {
    el.removeEventListener("click", el.__downloadHandler);
  }
};
