<template>
  <div v-if="show" class="mynav">
    <van-nav-bar
      class="van-hairline--bottom-none white-van-icon"
      :style="{
        background: 'rgba(0, 0, 0, 0)'
      }"
      left-arrow
      @click-left="onClickLeftHandler"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["isMobile"]),
    isAndroid() {
      return /android/i.test(navigator.userAgent);
    },
    show() {
      return this.isMobile && this.isAndroid;
    }
  },
  methods: {
    onClickLeftHandler() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.mynav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
::v-deep {
  .white-van-icon {
    .van-icon {
      color: #fff;
    }
  }
}
</style>
